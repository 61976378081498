import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map } from 'rxjs';

import { BaseChartDirective } from 'ng2-charts';

import { BaseChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/base-chart/base-chart.component';

/**
 * Chart for rating scale test cases.
 */
@Component({
	selector: 'app-rating-scale-chart',
	standalone: true,
	imports: [AsyncPipe, BaseChartDirective],
	templateUrl: './rating-scale-chart.component.html',
	styleUrl: './rating-scale-chart.component.scss',

	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingScaleChartComponent extends BaseChartComponent {
	/**
	 * Transform test case data into format compatible with chart and configure chart.
	 */
	public chartData$ = this.testCase$.pipe(
		map(testCase => {
			const preparedResult = testCase.testResults.map(testResult => {
				const results = testResult.result.map(item => Number(item));
				const data = {
					vehicle: testResult.vehicle,
					results: results,
				};
				return data;
			});

			return {
				type: 'boxplot',
				data: {
					labels: preparedResult.map(result => result.vehicle),
					datasets: [
						{
							label: 'min',
							borderWidth: 2,
							borderColor: '#888',
							fill: '-1',
							radius: 0,
							data: preparedResult.map(result => result.results),
							coef: 0,
							backgroundColor: 'rgba(121, 200, 121, 0.7)',
						},
					],
				},
				options: {
					indexAxis: 'y',
					plugins: {
						legend: {
							display: false,
						},
						datalabels: {
							display: false,
						},
						plugins: {
							displayMeanPlugin: {},
						},
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						displayColors: false,
					},
					responsive: true,
					title: {
						display: false,
					},
					scales: {
						x: {
							stacked: false,
							max: 10,
						},
						y: {
							stacked: false,
							scaleLabel: {
								display: true,
								labelString: 'value',
							},
						},
					},
					pan: {
						enabled: true,
						mode: 'x',
						speed: 10,
						threshold: 10,
					},
					zoom: {
						enabled: true,
						drag: false,
						mode: 'xy',
						limits: {
							max: 10,
							min: 0.5,
						},
					},
				},
				plugins: [
					{
						id: 'displayMeanPlugin',
						/**
						 * Simple plugin to display average value as text.
						 * @param chart
						 */
						afterDatasetsDraw(chart) {
							// Get chart data.
							const { ctx, chartArea } = chart;
							const { left, right, top, bottom } = chartArea;
							const chartHeight = bottom - top;
							const chartWidth = right - left;

							chart.data.datasets.forEach((dataset, datasetIndex) => {
								const meta = chart.getDatasetMeta(datasetIndex);
								if (!meta.data) return;

								// Get count of bars, to calculate height offset later.
								const dataCount = dataset.data.length;
								const dataHeight = chartHeight / dataCount;

								meta.data.forEach((element, index) => {
									// Get mean value of data.
									const meanValue = ((dataset.data[index].reduce((a, b) => a + b, 0) / dataset.data[index].length + Number.EPSILON) * 10) / 10; // Access mean value
									if (meanValue === undefined) return;

									// Define offsets relative to the chart size.
									// this is an arbitrary value chosen to put the text above the bar, within the bar area.
									const verticalOffset = dataHeight * -0.4;
									const horizontalOffset = chartWidth * -0.1;

									// Y coordinate should be based on the bar center point.
									const { y } = element.getCenterPoint('y');

									// X coordinate should be the average value. Y should be the middle plus the offset.
									const adjustedX = ((left + right) * meanValue) / 10 + horizontalOffset;
									const adjustedY = y + verticalOffset;

									ctx.save();
									ctx.fillStyle = '#888';
									ctx.textAlign = 'start';
									ctx.fillText(`Average: ${meanValue.toFixed(1)}`, adjustedX, adjustedY);
									ctx.restore();
								});
							});
						},
					},
				],
			} as any;
		}),
	);
}

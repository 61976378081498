<form [formGroup]="benchmarkFormService.benchmarkForm">
	<div formArrayName="testCases">
		@for (testCase of benchmarkFormService.benchmarkForm.controls.testCases.controls; track testCase; let i = $index) {
			<div class="test-case-container">
				@if (testCase.value; as testCaseValue) {
					<h2>{{ testCaseValue.testCaseName }}</h2>
					@if (!!testCaseValue.testCaseDescription) {
						<p-card>
							<div [innerHTML]="testCaseValue.testCaseDescription | safe: 'html'"></div>
						</p-card>
					}
					@switch (testCaseValue.testCaseType) {
						@case (InputType.RatingScale) {
							<app-rating-scale-chart [testCase]="testCaseValue"></app-rating-scale-chart>
						}
						@case (InputType.SingleSelection) {
							<app-single-selection-chart [testCase]="testCaseValue"></app-single-selection-chart>
						}
						@case (InputType.MultipleSelection) {
							<app-multiple-selection-chart [testCase]="testCaseValue"></app-multiple-selection-chart>
						}
						@case (InputType.YesNo) {
							<app-yesno-chart [testCase]="testCaseValue"></app-yesno-chart>
						}
						@case (InputType.PassFail) {
							<app-passfail-chart [testCase]="testCaseValue"></app-passfail-chart>
						}
						@case (InputType.Text) {
							<app-text-chart [testCase]="testCaseValue"></app-text-chart>
						}
						@case (InputType.Numeric) {
							<app-numeric-chart [testCase]="testCaseValue"></app-numeric-chart>
						}
					}
				}

				<div [formGroupName]="i">
					<!-- Pass the comments FormArray directly -->
					<app-comments-form [commentsForm]="testCase.controls.comments | formArray"></app-comments-form>
				</div>
			</div>
		}
	</div>
</form>

export enum InputType {
	PassFail = 'Pass Fail',
	RatingScale = 'Rating Scale',
	YesNo = 'YesNo',
	SingleSelection = 'Single Selection',
	MultipleSelection = 'Multiple Selection',
	Text = 'Text',
	OrderSelection = 'Order Selection',
	Numeric = 'Numeric',
}

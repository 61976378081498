<div class="benchmark-container">
	<div class="settings">
		<div class="buttons-container">
			<p-button label="Export report" (onClick)="exportReport()"></p-button>

			<div>
				<p-button (onClick)="fileInput.click()" label="Import report"></p-button>
				<input #fileInput type="file" style="display: none" (change)="importReport($event)" />
			</div>
		</div>

		<app-benchmark-search></app-benchmark-search>
		<div class="edit-button">
			<p-button [label]="benchmarkSettingsService.editingEnabled ? 'Disable editing' : 'Enable editing'" (onClick)="this.toggleEditingEnabled()"></p-button>
		</div>
		<app-benchmark-settings></app-benchmark-settings>
	</div>

	@if ({ isLoading: benchmarkFormService.reportLoading | async, benchmarkForm: benchmarkFormService.formValueChanges$ | async }; as source) {
		@if (source.isLoading) {
			<div class="loading-container">
				<p-progressSpinner></p-progressSpinner>
			</div>
		} @else if (source.benchmarkForm.testItemName) {
			<h1>{{ source.benchmarkForm.testItemName }}</h1>

			@if ((showTestSuiteDescription$ | async) && !!source.benchmarkForm.testItemDescription) {
				<p-card>
					<div [innerHTML]="source.benchmarkForm.testItemDescription | safe: 'html'"></div>
				</p-card>
			}
			@if (benchmarkSettingsService.editingEnabled) {
				<app-benchmark-form></app-benchmark-form>
			} @else {
				<app-benchmark-readonly></app-benchmark-readonly>
			}
		} @else {
			<p-card>
				<div>No report generated. Select test results to generate report.</div>
			</p-card>
		}
	}
</div>

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map } from 'rxjs';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';

import { BaseChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/base-chart/base-chart.component';

/**
 * Chart for text test cases.
 */
@Component({
	selector: 'app-text-chart',
	standalone: true,
	imports: [TableModule, AsyncPipe, CardModule],
	templateUrl: './text-chart.component.html',
	styleUrl: './text-chart.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextChartComponent extends BaseChartComponent {
	public tableData$ = this.testCase$.pipe(
		map(testCase => {
			return testCase.testResults.map(result => {
				return {
					vehicle: result.vehicle,
					answer: result.result,
				};
			});
		}),
	);
}

import { Injectable } from '@angular/core';

import { map, Observable, switchMap } from 'rxjs';

import { convertGraphqlResultsTestRunToResultCompleteTestRun, convertGraphqlResultTestCaseMetadataToResultTestCaseMetadata } from 'src/app/graphql/mappers/test-run.mapper';
import { GraphqlResultTestCaseMetadata } from 'src/app/graphql/models/output/graphql-result-test-case-metadata.model';
import { GraphqlResultsTestRun } from 'src/app/graphql/models/output/graphql-results-test-run.model';
import { GET_TEST_CASE_METADATA_QUERY, GET_TEST_RUN_QUERY } from 'src/app/graphql/queries/results-item-queries';
import { ResultCompleteTestRun } from 'src/app/models/result/result-complete-test-run.model';
import { ResultTestCaseMetadata } from 'src/app/models/result/result-test-case-metadata.model';
import { ApolloService } from 'src/app/services/apollo.service';

/**
 * Handles requests for queries related to results interfaces.
 */
@Injectable()
export class ResultsItemService {
	/**
	 * Constructor.
	 * @param apolloService To make graphql queries.
	 */
	constructor(private apolloService: ApolloService) {}

	/**
	 * Get a test run by UID.
	 * @param testRunUid UID of test run.
	 * @returns Requested test run.
	 */
	public getTestRun$(testRunUid: string): Observable<ResultCompleteTestRun> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(clients =>
				clients.abstractionLayerClient.query<{ resultsCompleteTestRun: GraphqlResultsTestRun }>({
					query: GET_TEST_RUN_QUERY,
					variables: { Uid: testRunUid },
				}),
			),
			map(result => convertGraphqlResultsTestRunToResultCompleteTestRun(result.data.resultsCompleteTestRun)),
		);
	}

	/**
	 * Gets description for given test case.
	 * @param testCaseUid UID of test case.
	 * @returns Description as a string.
	 */
	public getTestCaseMetadata$(testCaseUid: string): Observable<ResultTestCaseMetadata> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(clients =>
				clients.abstractionLayerClient.query<{ resultsTestCaseDetails: GraphqlResultTestCaseMetadata }>({
					query: GET_TEST_CASE_METADATA_QUERY,
					variables: { Uid: testCaseUid },
				}),
			),
			map(result => convertGraphqlResultTestCaseMetadataToResultTestCaseMetadata(result.data.resultsTestCaseDetails)),
		);
	}
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map } from 'rxjs';

import { BaseChartDirective } from 'ng2-charts';

import { BaseChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/base-chart/base-chart.component';

/**
 * Chart for single selection test cases.
 */
@Component({
	selector: 'app-single-selection-chart',
	standalone: true,
	imports: [AsyncPipe, BaseChartDirective],
	templateUrl: './single-selection-chart.component.html',
	styleUrl: './single-selection-chart.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectionChartComponent extends BaseChartComponent {
	/**
	 * Transform test case data into format compatible with chart and configure chart.
	 */
	public chartData$ = this.testCase$.pipe(
		map(testCase => {
			// Get all unique result strings
			const uniqueResults = Array.from(new Set(testCase.testResults.flatMap(item => item.result))).sort() as string[];

			// Get all vehicle names
			const vehicles = testCase.testResults.map(item => item.vehicle);

			// Transform data
			const chartData = {
				labels: vehicles,
				datasets: uniqueResults.map(resultType => {
					const counts = testCase.testResults.map(vehicleData => vehicleData.result.filter(r => r === resultType).length);
					return {
						label: resultType,
						data: counts,
						borderWidth: 1,
					};
				}),
			};

			return {
				data: chartData,
				options: {
					plugins: {
						datalabels: {
							display: false,
						},
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						displayColors: false,
					},
					responsive: true,
					title: {
						display: false,
					},
					scales: {
						x: {
							stacked: false,
						},
						y: {
							stacked: false,
							scaleLabel: {
								display: true,
								labelString: 'value',
							},
							ticks: {
								stepSize: 1,
							},
						},
					},
					pan: {
						enabled: true,
						mode: 'x',
						speed: 10,
						threshold: 10,
					},
					zoom: {
						enabled: true,
						drag: false,
						mode: 'xy',
						limits: {
							max: 10,
							min: 0.5,
						},
					},
				},
			};
		}),
	);
}

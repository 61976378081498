import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { shareReplay, startWith } from 'rxjs';

/**
 * Handles settings for benchmark report settings.
 */
@Injectable()
export class BenchmarkSettingsService {
	/**
	 * Whether or not the editing mode is enabled.
	 */
	public editingEnabled = false;

	public options = ['Test suite description', 'Test case description', 'Graphs', 'Positive comments', 'Negative comments', 'Neutral comments'];

	public optionsControl = new FormControl<string[]>(['Test suite description', 'Test case description', 'Graphs', 'Positive comments', 'Negative comments', 'Neutral comments']);

	public optionsControlValueChanges$ = this.optionsControl.valueChanges.pipe(startWith(this.optionsControl.value), shareReplay(1));

	public breakAfterTestCase = new FormControl<boolean>(false);

	/**
	 * Toggles view mode.
	 */
	public toggleEditingEnabled(): void {
		this.editingEnabled = !this.editingEnabled;
	}
}

import { Injectable } from '@angular/core';

import { Subject, shareReplay } from 'rxjs';

import { EnvironmentConfig } from 'tmt-logger';

import { environment } from 'src/environments/environment';

/**
 * Provides access to app configuration.
 */
@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	private logConfigSubject = new Subject<EnvironmentConfig>();

	public logConfig$ = this.logConfigSubject.asObservable().pipe(shareReplay(1));

	private configuration: any;

	/**
	 * Loads config from environment.
	 */
	public load() {
		this.configuration = environment;
		this.logConfigSubject.next(this.configuration.logConfig);
	}

	/**
	 * Gets current config.
	 * @returns Current configuration
	 */
	public getConfig(): any {
		return this.configuration;
	}
}

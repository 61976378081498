@if (tableData$ | async; as tableData) {
	<div class="table-container">
		<p-table [value]="tableData" styleClass="p-datatable-gridlines">
			<ng-template pTemplate="header">
				<tr>
					<th>Vehicle</th>
					<th>Answers</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-result>
				<tr>
					<td>{{ result.vehicle }}</td>
					<td>
						<ul class="list-container">
							@for (answer of result.answer; track answer) {
								<li>{{ answer }}</li>
							}
						</ul>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
}

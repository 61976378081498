<p-panel header="Test result search" [toggleable]="true" toggler="header" collapsed="true">
	<div class="panel-content">
		<form [formGroup]="searchForm">
			<div class="form-content">
				<div class="p-float-label">
					<p-calendar appUseUtc selectionMode="range" iconDisplay="input" showIcon="true" inputId="icondisplay" formControlName="DateRange" dateFormat="yy-mm-dd" readOnlyInput="false" />
					<label for="DateRange">Executed between dates</label>
				</div>
				<p-button label="Search" (onClick)="performTestRunSearch()" [disabled]=""></p-button>
			</div>
		</form>
		@if (benchmarkSearchService.testRuns$ | async; as testRuns) {
			@if (!(benchmarkSearchService.isLoadingTestRuns$ | async)) {
				<p-dropdown [options]="testSuiteOptions$ | async" [filter]="true" [formControl]="selectedTestSuite" placeholder="Select test suite"></p-dropdown>

				@if (availableTestRuns$ | async; as availableTestRuns) {
					@if (availableTestRuns.length) {
						<div class="table-container">
							<p-table
								[value]="availableTestRuns"
								[(selection)]="selectedTestRuns"
								selectionMode="multiple"
								[rows]="10"
								[showCurrentPageReport]="true"
								[rowsPerPageOptions]="[5, 10, 15]"
								[paginator]="true"
							>
								<ng-template pTemplate="header">
									<tr>
										<th style="width: 4rem"><p-tableHeaderCheckbox /></th>
										<th pSortableColumn="VehicleName">
											<div class="header-item">
												Vehicle name
												<p-sortIcon field="VehicleName"></p-sortIcon>
												<p-columnFilter field="VehicleName" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
													<ng-template pTemplate="filter" let-value let-filter="filterCallback">
														<p-multiSelect [options]="vehicleOptions$ | async" placeholder="Select vehicles" (onChange)="filter($event.value)"></p-multiSelect>
													</ng-template>
												</p-columnFilter>
											</div>
										</th>
										<th pSortableColumn="ExecutionTime">
											<div class="header-item">
												Execution date
												<p-sortIcon field="ExecutionTime"></p-sortIcon>
												<p-columnFilter type="date" field="ExecutionTime" display="menu" />
											</div>
										</th>
										<th pSortableColumn="ExecutedBy">
											<div class="header-item">
												Executed by
												<p-sortIcon field="ExecutedBy"></p-sortIcon>
												<p-columnFilter field="ExecutedBy" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
													<ng-template pTemplate="filter" let-value let-filter="filterCallback">
														<p-multiSelect [options]="userOptions$ | async" placeholder="Select users" (onChange)="filter($event.value)"></p-multiSelect>
													</ng-template>
												</p-columnFilter>
											</div>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-testRun>
									<tr>
										<td>
											<p-tableCheckbox [value]="testRun" />
										</td>
										<td>{{ testRun.VehicleName }}</td>
										<td>{{ testRun.LastEditedTime | date: 'YYYY-MM-dd HH:MM:SS' }}</td>
										<td>{{ testRun.ExecutedBy }}</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
					}
				}
			}

			<p-button label="Generate report" (onClick)="generateReport()"></p-button>
		}
		@if ((benchmarkSearchService.isLoadingTestRuns$ | async) && selectedTestSuite.valueChanges | async) {
			<p-table [value]="skeletonData">
				<ng-template pTemplate="header">
					<tr>
						<th></th>
						<th>Vehicle name</th>
						<th>Execution date</th>
						<th>Executed by</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-row>
					<tr>
						@for (col of skeletonColumns; track col) {
							<td>
								<p-skeleton width="100%" height="30px"></p-skeleton>
							</td>
						}
					</tr>
				</ng-template>
			</p-table>
		}
	</div>
</p-panel>

import { TargetEnvironment } from './environment.interfaces';

export const environment = {
	production: false,
	environment: TargetEnvironment.Hero,
	launchDarklyClientId: '664590c02c638010dc55095f',
	abstractionLayerStagingUrl: 'https://quobkvexmre7blsb7ltpdz2xxi.appsync-api.eu-north-1.amazonaws.com/graphql',
	abstractionLayerReleaseUrl: 'https://5oetsllb2jfelbghdyob2uy2r4.appsync-api.eu-north-1.amazonaws.com/graphql',
	apiStagingUrl: 'https://zqs2ldy2snbtrfnpmk5aogb3gu.appsync-api.eu-north-1.amazonaws.com/graphql',
	apiReleaseUrl: 'https://7vfjq2qqxbhj7cesnojb62xi3m.appsync-api.eu-north-1.amazonaws.com/graphql',
	logConfig: {
		application: 'reports',
		env: 'dev',
		graphqlUrl: 'https://kftht5tfp5b6tfwm6kzqa7ijzu.appsync-api.eu-north-1.amazonaws.com/graphql',
		logLevel: 2,
		console: true,
		debounceTime: 5000,
	},
};
export const Config = {
	colaConfig: {
		disabled: false,
		redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login',
	},
};

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

/**
 * Casts an abstract control to a formControl.
 */
@Pipe({
	name: 'formControl',
	standalone: true,
})
export class FormControlPipe implements PipeTransform {
	/**
	 * Transform function.
	 * @param value Input control.
	 * @returns Input as form control.
	 */
	public transform(value: AbstractControl): FormControl<(typeof value)['value']> {
		return value as FormControl<(typeof value)['value']>;
	}
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator to check that correct date range is chosen in calendar
 * @returns true if validation errors, otherwise null
 */
export function DateRangeValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return {
				noValue: true,
			};
		}

		if (!value?.length) {
			return {
				emptyArray: true,
			};
		}

		if (value.length !== 2) {
			return {
				incorrectArrayLength: true,
			};
		}

		if (!(value[0] instanceof Date && value[1] instanceof Date)) {
			return {
				incorrectContentType: true,
			};
		}

		return null;
	};
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { CommentType } from 'src/app/enums/comment-type.enum';
import { FormArrayPipe } from 'src/app/pipes/form-array.pipe';
import { FormControlPipe } from 'src/app/pipes/form-control.pipe';

/**
 * Form component for test case comments.
 */
@Component({
	selector: 'app-comments-form',
	standalone: true,
	imports: [CardModule, InputTextareaModule, ButtonModule, ReactiveFormsModule, FormArrayPipe, FormControlPipe],
	templateUrl: './comments-form.component.html',
	styleUrl: './comments-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsFormComponent {
	public CommentType = CommentType;

	/**
	 * Input comments to edit.
	 */
	@Input({ required: true }) commentsForm!: FormArray<FormGroup>;

	/**
	 * Adds a new field to input formArray.
	 * @param commentsArray Array to append to.
	 */
	public addComment(commentsArray: AbstractControl) {
		if (commentsArray instanceof FormArray) {
			commentsArray.push(new FormControl<string>(''));
		}
	}

	/**
	 * Removes selected index of input formArray.
	 * @param commentsArray Array to remove from.
	 * @param index Index to remove.
	 */
	public removeComment(commentsArray: AbstractControl, index: number) {
		if (commentsArray instanceof FormArray) {
			commentsArray.removeAt(index);
		}
	}

	/**
	 * Sets a selected comment as highlighted by moving it to the highlighted array.
	 * @param commentsArray Array wherein the original comment is.
	 * @param index Index of the comment in the array.
	 * @param commentType Type of comment, to tell where to move it.
	 */
	public setCommentHighlighted(commentsArray: AbstractControl, index: number, commentType: CommentType) {
		if (commentsArray instanceof FormArray) {
			const parentForm = commentsArray.parent;
			let targetArray: FormArray;

			switch (commentType) {
				case CommentType.Positive:
					targetArray = parentForm.controls['positiveHighlighted'];
					break;
				case CommentType.Negative:
					targetArray = parentForm.controls['negativeHighlighted'];
					break;
				case CommentType.Neutral:
					targetArray = parentForm.controls['neutralHighlighted'];
					break;
			}
			const valueToTransfer = commentsArray.at(index);

			commentsArray.removeAt(index);
			targetArray.push(valueToTransfer);
		}
	}

	/**
	 * Sets a selected comment as not highlighted by moving it from the highlighted array.
	 * @param commentsArray Array wherein the original comment is.
	 * @param index Index of the comment in the array.
	 * @param commentType Type of comment, to tell where to move it.
	 */
	public setCommentNotHighlighted(commentsArray: AbstractControl, index: number, commentType: CommentType) {
		if (commentsArray instanceof FormArray) {
			const parentForm = commentsArray.parent;
			let targetArray: FormArray;

			switch (commentType) {
				case CommentType.Positive:
					targetArray = parentForm.controls['positive'];
					break;
				case CommentType.Negative:
					targetArray = parentForm.controls['negative'];
					break;
				case CommentType.Neutral:
					targetArray = parentForm.controls['neutral'];
					break;
			}
			const valueToTransfer = commentsArray.at(index);

			commentsArray.removeAt(index);
			targetArray.insert(0, valueToTransfer);
		}
	}
}

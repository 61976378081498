import { enableProdMode, APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
//Import Scania components and theme
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';

import { ToastModule } from 'primeng/toast';
import { FloatLabelModule } from 'primeng/floatlabel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { LOGGER_CONFIG, ErrorHandlerService } from 'tmt-logger';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MessageService } from 'primeng/api';
import { Chart } from 'chart.js';
import datalabeChartDataLabels from 'chartjs-plugin-datalabels';
import { BoxAndWiskers, BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';

import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';
import { routes } from 'src/app/routes/routes';

import { AppConfigService } from './app/services/app-config.service';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

// Register chart.js plugin
Chart.register(datalabeChartDataLabels, BoxPlotController, BoxAndWiskers);
Chart.defaults.font.family = 'traton';
Chart.defaults.font.size = 16;

bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(routes),
		importProvidersFrom(BrowserModule, CalendarModule, DialogModule, FormsModule, ReactiveFormsModule, ProgressSpinnerModule, TableModule, SkeletonModule, FloatLabelModule, ToastModule),
		MessageService,
		ReportingGraphQLService,
		provideCharts(withDefaultRegisterables()),
		{
			provide: APP_INITIALIZER,
			useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: LOGGER_CONFIG,
			useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
			deps: [AppConfigService],
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
	],
}).catch(err => console.error(err));

import { Routes } from '@angular/router';

import { ReportsComponent } from 'src/app/pages/reports/reports.component';
import { BenchmarkComponent } from 'src/app/pages/benchmark/benchmark.component';

export const routes: Routes = [
	{ path: '', redirectTo: 'reports', pathMatch: 'full' },
	{
		path: 'reports',
		component: ReportsComponent,
	},
	{
		path: 'benchmark-demo',
		component: BenchmarkComponent,
	},
	{
		path: 'benchmark',
		component: BenchmarkComponent,
	},
];

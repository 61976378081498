import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { take } from 'rxjs';

import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';

import { AuthService } from 'src/app/services/auth.service';

/**
 * Main application header.
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [MenubarModule, AvatarModule, RouterLink, MenuModule, ButtonModule, AsyncPipe],
})
export class HeaderComponent {
	public title = 'Reports';

	public items = [{ label: 'Benchmark', route: '/benchmark' }];

	/**
	 * Constructor.
	 * @param authService To access user data.
	 */
	constructor(public authService: AuthService) {}

	/**
	 * Performs login operation.
	 */
	public login() {
		this.authService.login$().pipe(take(1)).subscribe();
	}
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MultipleSelectionChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/multiple-selection-chart/multiple-selection-chart.component';
import { BaseReadonlyTestCaseComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/base-readonly-test-case/base-readonly-test-case.component';

/**
 * Multiple selection readonly component.
 */
@Component({
	selector: 'app-readonly-multiple-selection',
	standalone: true,
	imports: [MultipleSelectionChartComponent, AsyncPipe],
	templateUrl: './readonly-multiple-selection.component.html',
	styleUrl: './readonly-multiple-selection.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyMultipleSelectionComponent extends BaseReadonlyTestCaseComponent {}

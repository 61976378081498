import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { TmtLoggerService } from 'tmt-logger';

import { UserData } from 'src/app/models/user-data.model';
import { Config } from 'src/environments/environment';

/**
 * Service handling user authorization and state.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
	/**
	 *
	 * @param router To get and manipulate query params.
	 * @param route To navigate.
	 * @param loggerService Logger.
	 */
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loggerService: TmtLoggerService,
	) {}

	private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({
		sessionID: localStorage.getItem('sessionId'),
		userName: localStorage.getItem('userName'),
		isLoggedIn: !!localStorage.getItem('sessionId'),
		companyName: localStorage.getItem('companyName'),
	} as UserData);

	public userData$ = this.userDataSubject.asObservable();

	public isLoggedIn$ = this.userData$.pipe(map(userData => !!userData.sessionID || !!localStorage.getItem('sessionId')));

	public startedRedirect = false;

	public sessionID$ = this.userData$.pipe(map(userData => userData.sessionID ?? localStorage.getItem('sessionId')));

	/**
	 * Clears current user session.
	 */
	public clearSession(): void {
		this.userDataSubject.next({ userName: '', sessionID: '', isLoggedIn: false, companyName: '' });

		// Clear data from local storage
		localStorage.removeItem('sessionId');
		localStorage.removeItem('userName');
		localStorage.removeItem('companyName');
	}

	/**
	 * Updates session with new values.
	 * @param sessionId
	 * @param username
	 * @param company
	 */
	public updateSession(sessionId: string, username: string, company: string) {
		const existingSessionId: string = localStorage.getItem('sessionId') ?? '';
		const existingUsername: string = localStorage.getItem('userName') ?? '';
		const existingCompany: string = localStorage.getItem('companyName') ?? '';

		if (existingCompany !== company || existingUsername !== username || existingSessionId !== sessionId) {
			localStorage.setItem('sessionId', sessionId);
			localStorage.setItem('userName', username);
			localStorage.setItem('companyName', company);

			this.userDataSubject.next({
				sessionID: sessionId,
				userName: username,
				companyName: company,
				isLoggedIn: true,
			} as UserData);
		} else {
			this.removeSpecificQueryParams(['sessionId', 'username', 'company']);
		}
	}

	/**
	 * Performs login operation.
	 * @returns Observable allowing for subscription of result.
	 */
	public login$(): Observable<any> {
		return this.isLoggedIn$.pipe(
			filter(isLoggedIn => !isLoggedIn),
			map(() => {
				const host = encodeURIComponent(window.location.href);
				const loginUrl = `${Config.colaConfig.redirectUrl}?origin=${host}`;
				if (this.startedRedirect === false) {
					this.startedRedirect = true;
					this.loggerService.flush();
					document.location.href = loginUrl;
				}
			}),
		);
	}

	/**
	 * Removes query params from current route.
	 * @param paramsToRemove Array of params to remove.
	 */
	private removeSpecificQueryParams(paramsToRemove: string[]) {
		// Get the current query parameters
		const queryParams = { ...this.route.snapshot.queryParams };

		// Remove specific parameters
		paramsToRemove.forEach(param => delete queryParams[param]);

		// Construct a new URL without the removed parameters
		const urlTree = this.router.createUrlTree([], { queryParams });

		// Navigate to the new URL
		this.router.navigateByUrl(urlTree);
	}
}

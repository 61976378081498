import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';
import moment from 'moment';

import { TestRunDTO } from 'src/app/models/dto/test-run';
import { ApolloService } from 'src/app/services/apollo.service';

/**
 * Service managing data needed to select test runs and generate reports.
 */
@Injectable()
export class ReportingGraphQLService {
	/**
	 * Track loading state of report. Used to disable some controls while report is loading.
	 */
	private reportLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public isLoadingReport$ = this.reportLoadingSubject.asObservable().pipe(shareReplay(1));

	/**
	 * Constructor.
	 * @param apolloService To get report data.
	 */
	constructor(private apolloService: ApolloService) {}

	/**
	 * Queries for a generated report for input test run.
	 * @param testRunUid Test run uid to get report for.
	 * @returns Report as a file.
	 */
	public getReport$(testRunUid: string): Observable<Blob | undefined> {
		// If no input, return undefined.
		if (!testRunUid) {
			return of(undefined);
		}
		return this.apolloService.apolloClients$.pipe(
			tap(() => this.reportLoadingSubject.next(true)),
			switchMap(apolloClients => {
				const query = gql`
					query reportsGenerateFilledPDF($testRunUid: String!) {
						reportsGenerateFilledPDF(TestRunUidCSV: $testRunUid)
					}
				`;
				const variables = {
					testRunUid: testRunUid,
				};

				return apolloClients['abstractionLayerClient'].query<{ reportsGenerateFilledPDF: string }>({
					query,
					variables,
				});
			}),
			map(result => {
				if (result.data && result.data.reportsGenerateFilledPDF) {
					const contentType = 'application/pdf';
					const blob = this.base64ToBlob(result.data.reportsGenerateFilledPDF, contentType);
					return blob;
				} else {
					return undefined;
				}
			}),
			take(1),
			shareReplay(1),
			finalize(() => this.reportLoadingSubject.next(false)),
		);
	}

	/**
	 * Get test runs matching input parameters.
	 * @param fromDate Initial date.
	 * @param toDate Final date.
	 * @param vehicleName Name of vehicle used in test run.
	 * @param vehicleVIN VIN of vehicle used in test run.
	 * @returns Observable of test runs matching input.
	 */
	public getTestRuns$(fromDate?: Date, toDate?: Date, vehicleName?: string, vehicleVIN?: string): Observable<TestRunDTO[]> {
		const fromDateString = fromDate ? `${moment(fromDate).format('YYYY-MM-DD')}` : '';
		const toDateString = toDate ? `${moment(toDate).add(1, 'day').format('YYYY-MM-DD')}` : '';

		return this.apolloService.apolloClients$.pipe(
			switchMap(clients => {
				const query = gql`
					query reportsTestRuns($fromDate: String!, $toDate: String!, $vehicleName: String, $vehicleVIN: String) {
						reportsTestRuns(FromDate: $fromDate, ToDate: $toDate, VehicleName: $vehicleName, VehicleVIN: $vehicleVIN) {
							Booking {
								FromDate
								Group
								ID
								Name
								RegBy
								ToDate
								User
								VehicleName
								VehicleVIN
							}
							ExecutedBy
							ExecutionTime
							LastEditedTime
							HasRestrictedItems
							LastEditedTime
							Properties {
								Name
								Value
							}
							TestRunUid
							TSUID
							TSVersion
							TSName
							TSItemNo
							TSTypeId
							VehicleName
							VehicleVIN
						}
					}
				`;

				const variables = {
					fromDate: fromDateString,
					toDate: toDateString,
					vehicleName: vehicleName,
					vehicleVIN: vehicleVIN,
				};

				return clients['abstractionLayerClient'].query<{ reportsTestRuns: TestRunDTO[] }>({
					query,
					variables,
				});
			}),
			map(result => {
				if (result.data && result.data.reportsTestRuns) {
					return JSON.parse(JSON.stringify(result.data.reportsTestRuns));
				} else {
					return <TestRunDTO[]>[];
				}
			}),
			catchError(() => of([])),
			shareReplay(1),
		);
	}

	/**
	 * Converts base64 string to blob.
	 * @param base64 Input string.
	 * @param contentType File type.
	 * @returns Blob with input content.
	 */
	private base64ToBlob(base64: string, contentType: string): Blob {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type: contentType });
	}
}

import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { BehaviorSubject, catchError, map, Observable, of, shareReplay, startWith, tap } from 'rxjs';

import { InputType } from 'zlib';

import { BenchmarkReport } from 'src/app/models/benchmark-report';

/**
 * Service handling benchmark form data.
 */
@Injectable()
export class BenchmarkFormService {
	/**
	 * Form containing current benchmark data.
	 * Will be populated with form values according to user selection.
	 */
	public benchmarkForm = new FormGroup({
		testItemName: new FormControl<string>(''),
		testItemDescription: new FormControl<string>(''),
		testCases: new FormArray<FormGroup<any>>([]),
	});

	/**
	 * Observable of form value changes with initial value and replay.
	 */
	public formValueChanges$ = this.benchmarkForm.valueChanges.pipe(startWith(this.benchmarkForm.value)).pipe(shareReplay(1));

	// Tracks loading state of report.
	public reportLoading = new BehaviorSubject(false);

	/**
	 * Exports the current report form value into a JSON file.
	 * @param filename Name of the file
	 */
	public exportReport$(): Observable<boolean> {
		return this.formValueChanges$.pipe(
			tap(formValue => {
				const a = document.createElement('a');
				a.setAttribute('href', 'data:text/plain;charset=utf-u,' + encodeURIComponent(JSON.stringify(formValue)));
				a.setAttribute('download', `Benchmark_${new Date().toISOString().slice(0, 10)}.json`);
				a.click();
			}),
			map(() => true),
			catchError(() => of(false)),
		);
	}

	/**
	 * Import a report from file.
	 * @param reportFile File to load report from. Expected to be JSON format.
	 * @returns Observable of operation result.
	 */
	public importReport$(reportFile: File): Observable<boolean> {
		return this.parseFileAsJson$<BenchmarkReport>(reportFile).pipe(
			tap(fileObject => this.setupFormValuesFromBenchmarkReport(fileObject)),
			map(() => true),
			catchError(() => of(false)),
		);
	}

	/**
	 * Parses input file into an object.
	 * @param file File to parse.
	 * @returns Object matching input JSON.
	 */
	public parseFileAsJson$<T>(file: File): Observable<T> {
		return new Observable<T>(observer => {
			const reader = new FileReader();

			// Success: File loaded
			reader.onload = () => {
				try {
					const json = JSON.parse(reader.result as string); // Parse as JSON
					observer.next(json); // Emit the parsed JSON
					observer.complete(); // Mark observable as complete
				} catch (error) {
					observer.error(`Invalid JSON: ${error.message}`); // Emit error
				}
			};

			// Error: FileReader encountered an issue
			reader.onerror = () => {
				observer.error('File could not be read.');
			};

			// Read the file as text
			reader.readAsText(file);
		});
	}

	/**
	 * Sets form values based on input benchmark report object.
	 * @param input Benchmark report values.
	 */
	public setupFormValuesFromBenchmarkReport(input: BenchmarkReport) {
		/*
		 * We need to set each nested form array manually, so we iterate through each level of the nested structure,
		 * to set the correct values. We then patch the values of the current form with these new values.
		 */

		// Iterate test cases.
		const testCaseArray = new FormArray<FormGroup<any>>([]);
		for (const testCase of input.testCases) {
			const testResultArray = new FormArray<FormGroup<any>>([]);

			// Iterate test results.
			for (const testResult of testCase.testResults) {
				// Iterate each individual result.
				const resultArray = new FormArray<FormControl<string>>([]);
				for (const result of testResult.result) {
					resultArray.push(new FormControl<string>(result));
				}

				const testResultGroup = new FormGroup({
					vehicle: new FormControl<string>(testResult.vehicle),
					result: resultArray,
				});

				testResultArray.push(testResultGroup);
			}

			// Iterate comments.
			const commentsArray = new FormArray<FormGroup<any>>([]);
			for (const comments of testCase.comments) {
				// Iterate positive comments.
				const positiveCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.positive ?? []) {
					positiveCommentsArray.push(new FormControl<string>(comment));
				}

				const positiveHighlightedCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.positiveHighlighted ?? []) {
					positiveHighlightedCommentsArray.push(new FormControl<string>(comment));
				}

				// Iterate negative comments.
				const negativeCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.negative ?? []) {
					negativeCommentsArray.push(new FormControl<string>(comment));
				}

				const negativeHighlightedCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.negativeHighlighted ?? []) {
					negativeHighlightedCommentsArray.push(new FormControl<string>(comment));
				}

				// Iterate neutral comments.
				const neutralCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.neutral ?? []) {
					neutralCommentsArray.push(new FormControl<string>(comment));
				}

				const neutralHighlightedCommentsArray = new FormArray<FormControl<string>>([]);
				for (const comment of comments.neutralHighlighted ?? []) {
					neutralHighlightedCommentsArray.push(new FormControl<string>(comment));
				}

				const testCommentsGroup = new FormGroup({
					vehicle: new FormControl<string>(comments.vehicle),
					positive: positiveCommentsArray,
					positiveHighlighted: positiveHighlightedCommentsArray,
					negative: negativeCommentsArray,
					negativeHighlighted: negativeHighlightedCommentsArray,
					neutral: neutralCommentsArray,
					neutralHighlighted: neutralHighlightedCommentsArray,
				});
				commentsArray.push(testCommentsGroup);
			}

			const testCaseGroup = new FormGroup({
				testCaseName: new FormControl<string>(testCase.testCaseName),
				testCaseDescription: new FormControl<string>(testCase.testCaseDescription),
				testCaseUid: new FormControl<string>(testCase.testCaseUid),
				testCaseType: new FormControl<InputType>(testCase.testCaseType),
				testResults: testResultArray,
				comments: commentsArray,
			});

			testCaseArray.push(testCaseGroup);
		}

		// PatchValue doesn't handle formArrays properly, so patch it to empty and then assign the newly created array.
		this.benchmarkForm.patchValue({
			testItemName: input.testItemName,
			testItemDescription: input.testItemDescription,
			testCases: [],
		});

		this.benchmarkForm.controls.testCases = testCaseArray;

		this.benchmarkForm.updateValueAndValidity();
	}

	/**
	 * Rechecks values, to ensure correct view, when necessary.
	 */
	public forceRecheckForm() {
		(this.benchmarkForm as any).updateValueAndValidity();
	}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { map, take, tap } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { BenchmarkReadonlyComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/benchmark-readonly.component';
import { BenchmarkFormService } from 'src/app/pages/benchmark/services/benchmark-form.service';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { BenchmarkSettingsService } from 'src/app/pages/benchmark/services/benchmark-settings.service';
import { BenchmarkSearchComponent } from 'src/app/pages/benchmark/components/benchmark-search/benchmark-search.component';
import { ResultsItemService } from 'src/app/pages/benchmark/services/results-item.service';

import { BenchmarkFormComponent } from './components/benchmark-form/benchmark-form.component';
import { BenchmarkSettingsComponent } from './components/benchmark-settings/benchmark-settings.component';

/**
 * Main benchmarking component.
 */
@Component({
	selector: 'app-benchmark',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		BenchmarkReadonlyComponent,
		BenchmarkSettingsComponent,
		BenchmarkFormComponent,
		ButtonModule,
		CardModule,
		DialogModule,
		InputTextModule,
		ProgressSpinnerModule,
		AsyncPipe,
		SafePipe,
		BenchmarkSettingsComponent,
		BenchmarkSearchComponent,
	],
	templateUrl: './benchmark.component.html',
	styleUrl: './benchmark.component.scss',
	providers: [BenchmarkFormService, BenchmarkSettingsService, ResultsItemService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenchmarkComponent {
	public showTestSuiteDescription$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Test suite description')));

	public exportDialogVisible = false;

	/**
	 * Constructor
	 * @param benchmarkFormService To get benchmark form data.
	 * @param benchmarkSettingsService To get and set benchmark settings.
	 * @param httpClient
	 */
	constructor(
		public benchmarkFormService: BenchmarkFormService,
		public benchmarkSettingsService: BenchmarkSettingsService,
	) {}

	/**
	 * Exports the current report to a JSON file.
	 * @returns No return value.
	 */
	public exportReport(): void {
		this.benchmarkFormService
			.exportReport$()
			.pipe(
				tap(() => {
					this.exportDialogVisible = false;
				}),
				take(1),
			)
			.subscribe();
	}

	/**
	 * Imports a report from user-uploaded file.
	 * @param event File upload event.
	 */
	public importReport(event: any): void {
		if (event.target.files) {
			this.benchmarkFormService.importReport$(event.target.files[0]).pipe(take(1)).subscribe();
		}
	}

	/**
	 * Toggles editing, and forces an update of form values, to ensure current data is displayed.
	 */
	public toggleEditingEnabled() {
		this.benchmarkSettingsService.toggleEditingEnabled();
		this.benchmarkFormService.forceRecheckForm();
	}
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SingleSelectionChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/single-selection-chart/single-selection-chart.component';
import { BaseReadonlyTestCaseComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/base-readonly-test-case/base-readonly-test-case.component';

/**
 * Single selection readonly component.
 * Should contain all data unique to single selection test cases.
 */
@Component({
	selector: 'app-readonly-single-selection',
	standalone: true,
	imports: [AsyncPipe, SingleSelectionChartComponent],
	templateUrl: './readonly-single-selection.component.html',
	styleUrl: './readonly-single-selection.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlySingleSelectionComponent extends BaseReadonlyTestCaseComponent {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';

import { BenchmarkSettingsService } from 'src/app/pages/benchmark/services/benchmark-settings.service';

/**
 * Handles benchmark settings.
 */
@Component({
	selector: 'app-benchmark-settings',
	standalone: true,
	imports: [ButtonModule, MultiSelectModule, ReactiveFormsModule, CheckboxModule],
	templateUrl: './benchmark-settings.component.html',
	styleUrl: './benchmark-settings.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenchmarkSettingsComponent {
	/**
	 * Constructor.
	 * @param benchmarkSettingsService To get and set benchmark settings.
	 */
	constructor(public benchmarkSettingsService: BenchmarkSettingsService) {}
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { YesnoChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/yesno-chart/yesno-chart.component';
import { BaseReadonlyTestCaseComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/base-readonly-test-case/base-readonly-test-case.component';

/**
 * Yes-no readonly component.
 */
@Component({
	selector: 'app-readonly-yesno',
	standalone: true,
	imports: [AsyncPipe, YesnoChartComponent],
	templateUrl: './readonly-yesno.component.html',
	styleUrl: './readonly-yesno.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyYesnoComponent extends BaseReadonlyTestCaseComponent {}

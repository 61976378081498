import { Injectable } from '@angular/core';

import { BehaviorSubject, map, ReplaySubject, switchMap, tap } from 'rxjs';

import { BenchmarkTestRunSearchParameters } from 'src/app/models/benchmark-test-run-search-parameters';
import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';

/**
 * Performs search behavior for test runs.
 */
@Injectable()
export class BenchmarkSearchService {
	private searchOptionsSubject = new ReplaySubject<BenchmarkTestRunSearchParameters>(1);

	private loadingSubject = new BehaviorSubject(false);

	public isLoadingTestRuns$ = this.loadingSubject.asObservable();

	/**
	 * Observable of all test runs matching current search conditions.
	 */
	public testRuns$ = this.searchOptionsSubject.pipe(
		switchMap(options => {
			this.loadingSubject.next(true);
			return this.reportingGraphQLService.getTestRuns$(options.fromDate, options.toDate);
		}),
		map(testRuns => (testRuns?.length ? testRuns.sort((a, b) => (b.LastEditedTime >= a.LastEditedTime ? 1 : -1)) : [])),
		tap(() => this.loadingSubject.next(false)),
	);

	/**
	 * Constructor.
	 * @param reportingGraphQLService To get test runs.
	 */
	constructor(private reportingGraphQLService: ReportingGraphQLService) {}

	/**
	 * Pushes new search params to search options subject to perform a search.
	 * @param searchParameters Conditions for search.
	 */
	public performSearch(searchParameters: BenchmarkTestRunSearchParameters) {
		this.searchOptionsSubject.next(searchParameters);
	}
}

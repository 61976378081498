<p-card header="Comments">
	<div class="card-content">
		@if (inputType === InputType.RatingScale) {
			<div class="positive-negative-container">
				@if (showPositiveComments$ | async) {
					<div class="comments-container positive">
						<p-card [style]="{ height: '100%', 'background-color': '#f0ffef' }">
							<ng-template pTemplate="header">
								<div class="card-header">
									<i class="pi pi-thumbs-up" style="font-size: 2rem"></i>
								</div>
							</ng-template>
							<div>
								@for (commentGroup of comments; track commentGroup.vehicle) {
									@if (commentGroup.positive.length || commentGroup.positiveHighlighted.length) {
										<b>{{ commentGroup.vehicle }}</b>
										<ul class="list-container">
											@for (comment of commentGroup.positiveHighlighted; track comment) {
												<li>
													<b>{{ comment }}</b>
												</li>
											}
											@for (comment of commentGroup.positive; track comment) {
												<li>{{ comment }}</li>
											}
										</ul>
									}
								}
							</div>
						</p-card>
					</div>
				}
				@if (showNegativeComments$ | async) {
					<div class="comments-container">
						<p-card [style]="{ height: '100%', 'background-color': '#ffefef' }">
							<ng-template pTemplate="header">
								<div class="card-header">
									<i class="pi pi-thumbs-down" style="font-size: 2rem"></i>
								</div>
							</ng-template>
							<div>
								@for (commentGroup of comments; track commentGroup.vehicle) {
									@if (commentGroup.negative.length || commentGroup.negativeHighlighted.length) {
										<b>{{ commentGroup.vehicle }}</b>
										<ul class="list-container">
											@for (comment of commentGroup.negativeHighlighted; track comment) {
												<li>
													<b>{{ comment }}</b>
												</li>
											}
											@for (comment of commentGroup.negative; track comment) {
												<li>{{ comment }}</li>
											}
										</ul>
									}
								}
							</div>
						</p-card>
					</div>
				}
			</div>
		}
		@if (showNeutralComments$ | async) {
			<div class="comments-container">
				<p-card [style]="{ 'background-color': '#fffaef' }">
					<ng-template pTemplate="header">
						<div class="card-header">
							<i class="pi pi-comment" style="font-size: 2rem"></i>
						</div>
					</ng-template>
					<div>
						@for (commentGroup of comments; track commentGroup.vehicle) {
							@if (commentGroup.neutral.length || commentGroup.neutralHighlighted.length) {
								<b>{{ commentGroup.vehicle }}</b>
								<ul class="list-container">
									@for (comment of commentGroup.neutralHighlighted; track comment) {
										<li>
											<b>{{ comment }}</b>
										</li>
									}
									@for (comment of commentGroup.neutral; track comment) {
										<li>{{ comment }}</li>
									}
								</ul>
							}
						}
					</div>
				</p-card>
			</div>
		}
	</div>
</p-card>

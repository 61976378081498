import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map } from 'rxjs';

import { CardModule } from 'primeng/card';

import { BenchmarkFormService } from 'src/app/pages/benchmark/services/benchmark-form.service';
import { ReadonlyRatingScaleComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-rating-scale/readonly-rating-scale.component';
import { ReadonlySingleSelectionComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-single-selection/readonly-single-selection.component';
import { ReadonlyCommentsComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-comments/readonly-comments.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { BenchmarkSettingsService } from 'src/app/pages/benchmark/services/benchmark-settings.service';
import { InputType } from 'src/app/enums/input-type.enum';
import { ReadonlyMultipleSelectionComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-multiple-selection/readonly-multiple-selection.component';
import { ReadonlyTextComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-text/readonly-text.component';
import { ReadonlyNumericComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-numeric/readonly-numeric.component';
import { ReadonlyYesnoComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-yesno/readonly-yesno.component';
import { ReadonlyPassfailComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/readonly-passfail/readonly-passfail.component';

/**
 * Main component for readonly benchmark report.
 */
@Component({
	selector: 'app-benchmark-readonly',
	standalone: true,
	imports: [
		AsyncPipe,
		CardModule,
		NgClass,
		ReadonlyRatingScaleComponent,
		ReadonlySingleSelectionComponent,
		ReadonlyMultipleSelectionComponent,
		ReadonlyTextComponent,
		ReadonlyNumericComponent,
		ReadonlyYesnoComponent,
		ReadonlyPassfailComponent,
		ReadonlyCommentsComponent,
		SafePipe,
	],
	templateUrl: './benchmark-readonly.component.html',
	styleUrl: './benchmark-readonly.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenchmarkReadonlyComponent {
	public InputType = InputType;

	public showTestCaseDescription$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Test case description')));

	public showGraphs$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Graphs')));

	/**
	 * Constructor.
	 * @param benchmarkFormService To get form data.
	 * @param benchmarkSettingsService
	 */
	constructor(
		public benchmarkFormService: BenchmarkFormService,
		public benchmarkSettingsService: BenchmarkSettingsService,
	) {}
}

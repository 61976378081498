import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Marks a resource as safe. Use when embedding content from a trusted source only.
 */
@Pipe({
	name: 'safe',
	standalone: true,
})
export class SafePipe implements PipeTransform {
	/**
	 * Constructor
	 * @param sanitizer To mark content as safe.
	 */
	constructor(protected sanitizer: DomSanitizer) {}

	/**
	 * Transform function.
	 * @param value Input value
	 * @param type Type of value.
	 * @returns Input marked as safe.
	 */
	public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
		switch (type) {
			case 'html':
				return this.sanitizer.bypassSecurityTrustHtml(value);
			case 'style':
				return this.sanitizer.bypassSecurityTrustStyle(value);
			case 'script':
				return this.sanitizer.bypassSecurityTrustScript(value);
			case 'url':
				return this.sanitizer.bypassSecurityTrustUrl(value);
			case 'resourceUrl':
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			default:
				throw new Error(`Invalid safe type specified: ${type}`);
		}
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

/**
 * Casts an abstract control to a formArray.
 */
@Pipe({
	name: 'formArray',
	standalone: true,
})
export class FormArrayPipe implements PipeTransform {
	/**
	 * Transform function.
	 * @param value Input control.
	 * @returns Input as form array.
	 */
	public transform(value: AbstractControl): FormArray {
		return value as FormArray;
	}
}

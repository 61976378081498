import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { map } from 'rxjs';

import { CardModule } from 'primeng/card';

import { BenchmarkSettingsService } from 'src/app/pages/benchmark/services/benchmark-settings.service';
import { InputType } from 'src/app/enums/input-type.enum';

/**
 * Readonly view of test case comments.
 */
@Component({
	selector: 'app-readonly-comments',
	standalone: true,
	imports: [CardModule, AsyncPipe],
	templateUrl: './readonly-comments.component.html',
	styleUrl: './readonly-comments.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyCommentsComponent {
	public InputType = InputType;

	@Input({ required: true }) comments: any;

	@Input({ required: true }) inputType: InputType;

	public showPositiveComments$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Positive comments')));

	public showNegativeComments$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Negative comments')));

	public showNeutralComments$ = this.benchmarkSettingsService.optionsControlValueChanges$.pipe(map(values => !!values.find(item => item === 'Neutral comments')));

	/**
	 * Constructor.
	 * @param benchmarkSettingsService To toggle visibilty according to settings
	 */
	constructor(public benchmarkSettingsService: BenchmarkSettingsService) {}
}

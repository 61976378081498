import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { CardModule } from 'primeng/card';

import { BenchmarkFormService } from 'src/app/pages/benchmark/services/benchmark-form.service';
import { CommentsFormComponent } from 'src/app/pages/benchmark/components/benchmark-form/components/comments-form/comments-form.component';
import { FormArrayPipe } from 'src/app/pipes/form-array.pipe';
import { RatingScaleChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/rating-scale-chart/rating-scale-chart.component';
import { SingleSelectionChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/single-selection-chart/single-selection-chart.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { InputType } from 'src/app/enums/input-type.enum';
import { MultipleSelectionChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/multiple-selection-chart/multiple-selection-chart.component';
import { YesnoChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/yesno-chart/yesno-chart.component';
import { PassfailChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/passfail-chart/passfail-chart.component';
import { TextChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/text-chart/text-chart.component';
import { NumericChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/numeric-chart/numeric-chart.component';

/**
 * Main component for benchmark form.
 * Contains general data, and creates child components based on test case type.
 */
@Component({
	selector: 'app-benchmark-form',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		CardModule,
		CommentsFormComponent,
		RatingScaleChartComponent,
		SingleSelectionChartComponent,
		MultipleSelectionChartComponent,
		YesnoChartComponent,
		PassfailChartComponent,
		TextChartComponent,
		NumericChartComponent,
		FormArrayPipe,
		SafePipe,
	],
	templateUrl: './benchmark-form.component.html',
	styleUrl: './benchmark-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenchmarkFormComponent {
	public InputType = InputType;

	/**
	 * Constructor.
	 * @param benchmarkFormService To access benchmark form.
	 */
	constructor(public benchmarkFormService: BenchmarkFormService) {}
}

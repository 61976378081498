import { gql } from 'apollo-angular';

export const GET_TEST_RUN_QUERY = gql`
	query resultsCompleteTestRun($Uid: ID!) {
		resultsCompleteTestRun(Uid: $Uid) {
			TestSuite {
				Name
				RegBy
				RegTime
				TestSuiteUid
				Version
				Description
			}
			TestRunType
			TestRun {
				Configuration
				ExecutionTime
				RegBy
				RegTime
				TestRunUid
				Status
				TestResults {
					ExecutedBy
					FailedBy
					FailedReason
					FailedReasonComment
					FailedReasonText
					InputType
					MainTestSuiteUid
					NegativeComment
					PositiveComment
					Result
					ResultComment
					TestCaseUid
					TestRunType
					VehicleName
				}
			}
		}
	}
`;

export const GET_TEST_CASE_METADATA_QUERY = gql`
	query testCaseMetadata($Uid: ID!) {
		resultsTestCaseDetails(TestCaseUid: $Uid) {
			Version
			TCUID
			Name
			Description
			InputTypeId
		}
	}
`;

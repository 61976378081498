import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TextChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/text-chart/text-chart.component';
import { BaseReadonlyTestCaseComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/base-readonly-test-case/base-readonly-test-case.component';

/**
 * Text readonly component.
 */
@Component({
	selector: 'app-readonly-text',
	standalone: true,
	imports: [AsyncPipe, TextChartComponent],
	templateUrl: './readonly-text.component.html',
	styleUrl: './readonly-text.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyTextComponent extends BaseReadonlyTestCaseComponent {}

<div class="settings-container">
	<div>
		<p-multiSelect
			[displaySelectedLabel]="false"
			placeholder="Toggle sections"
			[options]="benchmarkSettingsService.options"
			[formControl]="benchmarkSettingsService.optionsControl"
		></p-multiSelect>
	</div>

	<div>
		<p-checkbox [formControl]="benchmarkSettingsService.breakAfterTestCase" [binary]="true" inputId="pageBreak" />
		<label for="pageBreak">Break page after each test case when printing</label>
	</div>
</div>

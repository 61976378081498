import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PassfailChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/passfail-chart/passfail-chart.component';
import { BaseReadonlyTestCaseComponent } from 'src/app/pages/benchmark/components/benchmark-readonly/components/base-readonly-test-case/base-readonly-test-case.component';

/**
 * Text pass-fail component.
 */
@Component({
	selector: 'app-readonly-passfail',
	standalone: true,
	imports: [AsyncPipe, PassfailChartComponent],
	templateUrl: './readonly-passfail.component.html',
	styleUrl: './readonly-passfail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyPassfailComponent extends BaseReadonlyTestCaseComponent {}

import { InputType } from 'src/app/enums/input-type.enum';
import { GraphqlInputType } from 'src/app/graphql/enums/graphql-input-type.enum';
import { GraphqlResultTestCaseMetadata } from 'src/app/graphql/models/output/graphql-result-test-case-metadata.model';
import { GraphqlResultsTestRun } from 'src/app/graphql/models/output/graphql-results-test-run.model';
import { GraphqlTestResult } from 'src/app/graphql/models/output/graphql-test-result.model';
import { GraphqlTestRun } from 'src/app/graphql/models/output/graphql-test-run.model';
import { GraphqlTestSuite } from 'src/app/graphql/models/output/graphql-test-suite.model';
import { ResultCompleteTestRun } from 'src/app/models/result/result-complete-test-run.model';
import { ResultTestCaseMetadata } from 'src/app/models/result/result-test-case-metadata.model';
import { ResultTestResult } from 'src/app/models/result/result-test-result.model';
import { ResultTestRun } from 'src/app/models/result/result-test-run.model';
import { ResultTestSuite } from 'src/app/models/result/result-test-suite.model';

/**
 * Converts graphql complete test run to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
export function convertGraphqlResultsTestRunToResultCompleteTestRun(input: GraphqlResultsTestRun): ResultCompleteTestRun | undefined {
	if (!input) {
		return undefined;
	}

	return {
		TestRunType: input.TestRunType,
		TestSuite: convertGraphqlTestSuiteToResultTestSuite(input.TestSuite),
		TestRun: convertGraphqlTestRunToResultTestRun(input.TestRun),
	};
}

/**
 * Converts graphql test case metadata to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
export function convertGraphqlResultTestCaseMetadataToResultTestCaseMetadata(input: GraphqlResultTestCaseMetadata): ResultTestCaseMetadata | undefined {
	if (!input) {
		return undefined;
	}

	return {
		Uid: input.TCUID,
		Name: input.Name,
		Description: input.Description,
		Version: input.Version,
		InputType: convertGraphqlInputTypeToInputType(input.InputTypeId),
	};
}

/**
 * Converts graphql test suite to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
function convertGraphqlTestSuiteToResultTestSuite(input: GraphqlTestSuite): ResultTestSuite | undefined {
	if (!input) {
		return undefined;
	}

	return {
		Name: input.Name,
		RegBy: input.RegBy,
		RegTime: input.RegTime ? new Date(input.RegTime) : undefined,
		TestSuiteUid: input.TestSuiteUid,
		Version: input.Version,
		Description: input.Description,
	};
}

/**
 * Converts graphql test run to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
function convertGraphqlTestRunToResultTestRun(input: GraphqlTestRun): ResultTestRun | undefined {
	if (!input) {
		return undefined;
	}

	return {
		Configuration: input.Configuration,
		ExecutionTime: input.ExecutionTime ? new Date(input.ExecutionTime) : undefined,
		RegBy: input.RegBy,
		RegTime: input.RegTime ? new Date(input.RegTime) : undefined,
		TestRunUid: input.TestRunUid,
		Status: input.Status,
		TestResults: input.TestResults.map(item => convertGraphqlTestResultToResultTestResult(item)).filter(item => !!item) as ResultTestResult[],
	};
}

/**
 * Converts graphql test result to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
function convertGraphqlTestResultToResultTestResult(input: GraphqlTestResult): ResultTestResult | undefined {
	if (!input) {
		return undefined;
	}

	return {
		ExecutedBy: input.ExecutedBy,
		FailedBy: input.FailedBy,
		FailedReson: input.FailedReson,
		FailedReasonComment: input.FailedReasonComment,
		FailedReasonText: input.FailedReasonText,
		InputType: convertGraphqlInputTypeToInputType(input.InputType),
		MainTestSuiteUid: input.MainTestSuiteUid,
		NegativeComment: input.NegativeComment,
		PositiveComment: input.PositiveComment,
		ResultComment: input.ResultComment,
		Result: input.Result,
		TestCaseName: input.TestCaseName,
		TestCaseItemNo: input.TestCaseItemNo,
		TestCaseUid: input.TestCaseUid,
		TestCaseVersion: input.TestCaseVersion,
		TestRunType: input.TestRunType,
		VehicleName: input.VehicleName,
	};
}

/**
 * Converts graphql input type to internal model.
 * @param input Graphql model.
 * @returns Corresponding internal model.
 */
function convertGraphqlInputTypeToInputType(input: GraphqlInputType): InputType | undefined {
	switch (input) {
		case GraphqlInputType.MultipleSelection:
			return InputType.MultipleSelection;
		case GraphqlInputType.PassFail:
			return InputType.PassFail;
		case GraphqlInputType.YesNo:
			return InputType.YesNo;
		case GraphqlInputType.SingleSelection:
			return InputType.SingleSelection;
		case GraphqlInputType.Text:
			return InputType.Text;
		case GraphqlInputType.OrderSelection:
			return InputType.OrderSelection;
		case GraphqlInputType.Numeric:
			return InputType.Numeric;
		case GraphqlInputType.RatingScale:
			return InputType.RatingScale;
		default:
			return undefined;
	}
}

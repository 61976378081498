import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { map } from 'rxjs';

import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BaseChartComponent } from 'src/app/pages/benchmark/components/benchmark-charts/base-chart/base-chart.component';
/**
 * Chart for yes-no test cases.
 */
@Component({
	selector: 'app-yesno-chart',
	standalone: true,
	imports: [AsyncPipe, BaseChartDirective],
	templateUrl: './yesno-chart.component.html',
	styleUrl: './yesno-chart.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesnoChartComponent extends BaseChartComponent {
	/**
	 * Transform test case data into format compatible with chart and configure chart.
	 */
	public chartData$ = this.testCase$.pipe(
		map(testCase => {
			const preparedResult = testCase.testResults.map(testResult => {
				const yesResults = testResult.result.filter(item => item.toLocaleLowerCase() === 'yes').length;
				const noResults = testResult.result.filter(item => item.toLocaleLowerCase() === 'no').length;
				const totalResults = yesResults + noResults;

				const noPercentage = (noResults / totalResults) * 100;

				const data = {
					vehicle: testResult.vehicle,
					splitPoint: Math.round(noPercentage),
				};
				return data;
			});

			return {
				data: {
					labels: preparedResult.map(result => result.vehicle),
					datasets: [
						{
							label: 'No (%)',
							backgroundColor: 'rgba(240, 140, 121, 0.8)',
							borderWidth: 0,
							fill: '-1',
							radius: 0,
							data: preparedResult.map(data => [0, data.splitPoint]),
							datalabels: {
								display: true,
								anchor: 'center' as any,
								/**
								 * Formatter function. Writes out average result value.
								 * @param value Bar value array.
								 * @returns Formatted label text.
								 */
								formatter: function (value) {
									return value[1];
								},
							},
						},
						{
							label: 'Yes (%)',
							backgroundColor: 'rgba(121, 200, 121, 0.8)',
							borderWidth: 0,
							fill: '-1',
							radius: 0,
							data: preparedResult.map(data => [data.splitPoint, 100]),
							datalabels: {
								display: true,
								anchor: 'center' as any,
								/**
								 * Formatter function. Writes out average result value.
								 * @param value Bar value array.
								 * @returns Formatted label text.
								 */
								formatter: function (value) {
									return 100 - value[0];
								},
							},
						},
					],
				},
				plugins: [ChartDataLabels],
				options: {
					indexAxis: 'y',
					plugins: {
						legend: {
							display: true,
						},
						datalabels: {
							display: false,
						},
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						displayColors: false,
					},
					responsive: true,
					title: {
						display: false,
					},
					scales: {
						y: {
							stacked: true,
						},
						x: {
							stacked: false,
							scaleLabel: {
								display: true,
								labelString: 'value',
							},
							min: 0,
							max: 100,
						},
					},
					pan: {
						enabled: true,
						mode: 'x',
						speed: 10,
						threshold: 10,
					},
					zoom: {
						enabled: true,
						drag: false,
						mode: 'xy',
						limits: {
							max: 10,
							min: 0.5,
						},
					},
				} as any,
			};
		}),
	);
}

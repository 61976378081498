import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { BehaviorSubject, ReplaySubject, catchError, filter, map, of, startWith, switchMap, tap } from 'rxjs';

import { Button } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FloatLabelModule } from 'primeng/floatlabel';

import { LaunchDarklyService } from 'src/app/services/launchdarkly.service';
import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';
import { TestRunDTO } from 'src/app/models/dto/test-run';

/**
 * Component displaying reports.
 */
@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrl: './reports.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, FloatLabelModule, CalendarModule, TableModule, PrimeTemplate, SkeletonModule, Button, AsyncPipe],
})
export class ReportsComponent implements AfterViewInit {
	/**
	 * Subject of currently selected test run, updated from table of test runs in template.
	 */
	private selectedTestRunSubject: ReplaySubject<TestRunDTO> = new ReplaySubject(1);

	public selectedTestRun$ = this.selectedTestRunSubject.asObservable().pipe(
		tap(testRun => {
			// Reset our report subject when changing test run to remove currently displayed report, if any.
			this.reportInputSubject.next('');

			// When selecting a test run, stringify it and put it into query parameters.
			// This allows for restoration of state on reload, and enables link sharing.
			const queryParams: Params = { testRun: btoa(JSON.stringify(testRun)) };

			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams,
				queryParamsHandling: 'merge',
				replaceUrl: true,
			});
		}),
	);

	// subject tracking our input for creating a report.
	// Currently expects a test run UID, update with more params as necessary.
	private reportInputSubject: ReplaySubject<string> = new ReplaySubject(1);

	// Observable of URL to generated report. Can be used to embed or download report.
	public pdfUrl$ = this.reportInputSubject.pipe(
		switchMap(testRun =>
			this.reportingGraphQLService.getReport$(testRun).pipe(
				catchError(() => {
					this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to generate report.' });
					return of(undefined);
				}),
			),
		),
		map(results => {
			if (!results) {
				return undefined;
			}
			const url = URL.createObjectURL(results);
			return this.sanitizer.bypassSecurityTrustResourceUrl(url);
		}),
	);

	// Tracks our loading state, to ensure skeleton table is shown while fetching new data.
	public testRunsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public reportLoading$ = this.reportingGraphQLService.isLoadingReport$;

	// Placeholder data for skeleton rows
	public skeletonData = Array(5).fill({});

	// Placeholder data for skeleton columns
	public skeletonColumns = Array(6).fill({});

	// Max date for calendar input. Set to current date.
	public maxDate = new Date();

	/**
	 * Definition of test run list input form.
	 * Currently only requests by date, but implemented as a form group to allow extension.
	 */
	public reportInputForm = new FormGroup({
		dateRange: new FormControl<Date[]>([
			new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // Initially, set start date to one week before now.
			new Date(),
		]),
	});

	/**
	 * Observable of test runs matching current selection in form.
	 * Starts with initial value of form, then fires each time new valid input is given in form.
	 */
	public testRuns$ = this.reportInputForm.valueChanges.pipe(
		startWith(this.reportInputForm.getRawValue()), // Start with the initial value of the form.
		filter(input => input?.dateRange[0] && input?.dateRange[1] && input.dateRange[0] <= input.dateRange[1]),
		tap(input => {
			this.testRunsLoading.next(true);

			// When updating our dates, add selected dates into query parameters.
			// This allows for restoration of state on reload, and enables link sharing.
			const queryParams: Params = { fromDate: input.dateRange[0].toISOString(), toDate: input.dateRange[1].toISOString() };

			// Navigate to current page without adding to browser history, updating our query parameters.
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams,
				queryParamsHandling: 'merge',
				replaceUrl: true,
			});
		}),
		switchMap(input => this.reportingGraphQLService.getTestRuns$(input.dateRange[0], input.dateRange[1])),
		tap(() => this.testRunsLoading.next(false)),
	);

	/**
	 * Constructor.
	 * @param reportingGraphQLService To get test runs and reports.
	 * @param sanitizer To create trusted url for report PDF.
	 * @param activatedRoute To get current route for saving component state.
	 * @param router To update our current route with new query parameters.
	 * @param messageService
	 * @param ldService
	 */
	constructor(
		private reportingGraphQLService: ReportingGraphQLService,
		private sanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private messageService: MessageService,
		public ldService: LaunchDarklyService,
	) {}

	/**
	 * On init, check our query parameters and restore state if possible.
	 * Checks for selected date range as well as report selected for report generation.
	 */
	ngAfterViewInit(): void {
		const params = this.activatedRoute.snapshot.queryParams;
		if (params['fromDate'] && params['toDate']) {
			this.reportInputForm.patchValue({
				dateRange: [new Date(params['fromDate']), new Date(params['toDate'])],
			});
			this.reportInputForm.updateValueAndValidity();
		}
		if (params['testRun']) {
			this.selectedTestRunSubject.next(JSON.parse(atob(params['testRun'])));
		}
	}

	/**
	 * Updates currently selected test run upon event.
	 * @param event Change event from test run table.
	 */
	public testRunSelected(event: any) {
		this.selectedTestRunSubject.next(event.data);
	}

	/**
	 * Generates a report for currently selected test run.
	 * TODO: Currently expects only a single report type, extend to allow for various reports.
	 * @param testRunUid
	 */
	public generateReport(testRunUid: string) {
		this.reportInputSubject.next(testRunUid);
	}
}

@if (benchmarkFormService.formValueChanges$ | async; as formValue) {
	@for (testCase of formValue.testCases; track testCase.testCaseUid) {
		<div class="test-case-container" [ngClass]="(benchmarkSettingsService.breakAfterTestCase.valueChanges | async) ? 'page-break' : 'no-page-break'">
			<h2>{{ testCase.testCaseName }}</h2>

			@if ((showTestCaseDescription$ | async) && !!testCase.testCaseDescription) {
				<p-card>
					<div [innerHTML]="testCase.testCaseDescription | safe: 'html'"></div>
				</p-card>
			}
			@if (showGraphs$ | async) {
				@switch (testCase.testCaseType) {
					@case (InputType.RatingScale) {
						<app-readonly-rating-scale [testCase]="testCase"></app-readonly-rating-scale>
					}
					@case (InputType.SingleSelection) {
						<app-readonly-single-selection [testCase]="testCase"></app-readonly-single-selection>
					}
					@case (InputType.MultipleSelection) {
						<app-readonly-multiple-selection [testCase]="testCase"></app-readonly-multiple-selection>
					}
					@case (InputType.Text) {
						<app-readonly-text [testCase]="testCase"></app-readonly-text>
					}
					@case (InputType.Numeric) {
						<app-readonly-numeric [testCase]="testCase"></app-readonly-numeric>
					}
					@case (InputType.PassFail) {
						<app-readonly-passfail [testCase]="testCase"></app-readonly-passfail>
					}
					@case (InputType.YesNo) {
						<app-readonly-yesno [testCase]="testCase"></app-readonly-yesno>
					}
				}
			}
			<app-readonly-comments [comments]="testCase.comments" [inputType]="testCase.testCaseType"></app-readonly-comments>
		</div>
	}
}

import { Injectable } from '@angular/core';

import { combineLatest, concatMap, from, map, mergeMap, Observable, of, take, toArray } from 'rxjs';

import { BenchmarkReport } from 'src/app/models/benchmark-report';
import { BenchmarkTestCase } from 'src/app/models/benchmark-test-case';
import { ResultCompleteTestRun } from 'src/app/models/result/result-complete-test-run.model';
import { ResultTestCaseMetadata } from 'src/app/models/result/result-test-case-metadata.model';
import { ResultsItemService } from 'src/app/pages/benchmark/services/results-item.service';

/**
 * Handles generating benchmark reports.
 */
@Injectable()
export class BenchmarkReportService {
	/**
	 * Constructor.
	 * @param resultsItemService To fetch test runs from database.
	 */
	constructor(private resultsItemService: ResultsItemService) {}

	/**
	 * Generate benchmark report by getting test runs from database and parse them.
	 * @param testRunUids UIDs of test runs to generate report from.
	 * @returns Report object.
	 */
	public generateBenchmarkReport$(testRunUids: string[]): Observable<BenchmarkReport | undefined> {
		if (!testRunUids?.length) {
			return of(undefined);
		}

		//Create an observable for each test run, which fetches data for that test run.
		return from(testRunUids).pipe(
			mergeMap(testRunUid => this.resultsItemService.getTestRun$(testRunUid).pipe(take(1))),
			toArray(),
			map(testRuns => testRuns.filter(testRun => testRun.TestRun && testRun.TestRun.TestResults)), // Check that there are actually results.
			mergeMap(testRuns => {
				// Find all unique test cases in the list of test results.
				const testResults = [...new Set((testRuns?.map(testRun => testRun.TestRun?.TestResults?.map(testResult => testResult?.TestCaseUid) ?? []) ?? []).flat())];

				// For each test case, fetch details to get relevant metadata.
				return combineLatest({
					testRuns: of(testRuns),
					testCases: from(testResults).pipe(
						concatMap(testCaseUid => this.resultsItemService.getTestCaseMetadata$(testCaseUid).pipe(take(1))),
						toArray(),
					),
				});
			}),
			map(result => this.generateReportFromTestRuns(result.testRuns, result.testCases)),
			take(1),
		);
	}

	/**
	 * Generates a benchmark report object based on a list of test runs.
	 * @param resultsTestRuns List of input test runs.
	 * @param resultsTestCases
	 * @returns Report of input test runs.
	 */
	private generateReportFromTestRuns(resultsTestRuns: ResultCompleteTestRun[], resultsTestCases: ResultTestCaseMetadata[]): BenchmarkReport | undefined {
		if (!resultsTestRuns?.length || !resultsTestCases.length) {
			return undefined;
		}

		const testRuns = resultsTestRuns.map(testRun => testRun.TestRun);

		// Setup all test cases.
		const testCases = resultsTestCases.map(testCase => {
			return {
				testCaseName: testCase.Name,
				testCaseUid: testCase.Uid,
				testCaseType: testCase.InputType,
				testCaseDescription: testCase.Description,
				testResults: [],
				comments: [],
			};
		});

		// Loop all test runs.
		for (const testRun of testRuns) {
			// Loop each test result in test run.
			for (const testResult of testRun.TestResults) {
				// Find test case by matching UID of test result.
				const existingTestCase: BenchmarkTestCase = testCases.find(testCase => testCase.testCaseUid === testResult.TestCaseUid);

				// TODO FIXME Workaround, type seems to be broken in test case query.
				existingTestCase.testCaseType = testResult.InputType;

				// If we have a result, add it to the test case.
				if (testResult.Result) {
					const existingVehicleResult = existingTestCase.testResults.find(result => result.vehicle === testResult.VehicleName);

					if (existingVehicleResult) {
						existingVehicleResult.result.push(testResult.Result);
					} else {
						existingTestCase.testResults.push({
							vehicle: testResult.VehicleName,
							result: [testResult.Result],
						});
					}
				}

				// Find or add comments to test case.
				let existingComments = existingTestCase.comments.find(comments => comments.vehicle === testResult.VehicleName);
				if (!existingComments) {
					existingComments = {
						vehicle: testResult.VehicleName,
						positive: [],
						positiveHighlighted: [],
						negative: [],
						negativeHighlighted: [],
						neutral: [],
						neutralHighlighted: [],
					};
					existingTestCase.comments.push(existingComments);
				}

				// Add each comment type as necessary.
				if (testResult.PositiveComment) {
					existingComments.positive.push(testResult.PositiveComment);
				}

				if (testResult.NegativeComment) {
					existingComments.negative.push(testResult.NegativeComment);
				}

				if (testResult.ResultComment) {
					existingComments.neutral.push(testResult.ResultComment);
				}
			}
		}

		// Return complete benchmark report object.
		return {
			testItemName: resultsTestRuns[0].TestSuite.Name,
			testItemDescription: resultsTestRuns[0].TestSuite.Description,
			testCases: testCases,
		};
	}
}

import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router, RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AuthService } from './services/auth.service';
import { HeaderComponent } from './components/header/header.component';

/**
 * App component.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [AsyncPipe, ToastModule, HeaderComponent, RouterOutlet, ProgressSpinnerModule],
})
export class AppComponent implements OnInit {
	public isLoggedIn$ = this.authService.userData$.pipe(map(userData => !!userData.sessionID));

	private destroyRef = inject(DestroyRef);

	params$: Observable<ParamMap> = this.route.queryParamMap;

	navigationEnded$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

	/**
	 *
	 * @param route To get query params from route.
	 * @param router To track router events.
	 * @param authService To get user data and setup session.
	 */
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
	) {}

	/**
	 * On init, setup subscription of user data.
	 */
	ngOnInit(): void {
		combineLatest({
			params: this.params$,
			navigationEnded: this.navigationEnded$,
			userData: this.authService.userData$,
		})
			.pipe(
				tap(source => {
					if (source.params.get('sessionId')) {
						const sessionId = source.params.get('sessionId') ?? '';
						const username = source.params.get('username') ?? '';
						const company = source.params.get('company') ?? '';
						this.authService.updateSession(sessionId, username, company);
					}
				}),
				switchMap(() => {
					return this.authService.login$();
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();

		this.authService.userData$.subscribe();
	}
}

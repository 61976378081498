import { Directive, Host, Self } from '@angular/core';

import { Calendar } from 'primeng/calendar';

/**
 * Directive used to ensure primeng creates timestamps that are correct UTC time matching the view.
 * This is necessary in cases where it is important that the date component of the timestamp is corect.
 * This solution is taken from discussion at https://github.com/primefaces/primeng/issues/5853.
 * It is explicitly a dirty solution, but is sufficient for our purposes.
 * It should only be used when a calendar is used as a date picker, ignoring time component.
 */
@Directive({
	selector: '[appUseUtc]',
	standalone: true,
})
export class UseUtcDirective {
	/**
	 *
	 * @param calendar
	 */
	constructor(@Host() @Self() private calendar: Calendar) {
		const oUpdateModel: (value: any) => void = this.calendar.updateModel;

		this.calendar.updateModel = function (value: any) {
			if (calendar.isMultipleSelection()) {
				console.warn('useUtc directive is not implemented for multiple selection');
				oUpdateModel.call(calendar, value);
				return;
			}

			if (!value) {
				oUpdateModel.call(calendar, value);
				return;
			}
			if (calendar.dataType !== 'date') {
				oUpdateModel.call(calendar, value);
				return;
			}

			const asUtc = (date: Date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

			if (calendar.isRangeSelection()) {
				for (let i = 0; i < value.length; i++) {
					value[i] && (value[i] = asUtc(value[i]));
				}
			} else {
				value = asUtc(value);
			}

			oUpdateModel.call(calendar, value);
		}.bind(this);
	}
}

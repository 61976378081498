import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReplaySubject } from 'rxjs';

/**
 * Base component for a readonly test case.
 * Only contains test case input, for now.
 */
@Component({
	selector: 'app-base-readonly-test-case',
	standalone: true,
	imports: [],
	templateUrl: './base-readonly-test-case.component.html',
	styleUrl: './base-readonly-test-case.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseReadonlyTestCaseComponent {
	/**
	 * Setter for input test case, which pushes to a replaySubject.
	 */
	@Input({ required: true }) public set testCase(v) {
		this.testCaseSubject.next(v);
	}

	private testCaseSubject = new ReplaySubject<any>(1);

	protected testCase$ = this.testCaseSubject.asObservable();
}

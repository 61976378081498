export enum GraphqlInputType {
	PassFail = 143,
	RatingScale = 144,
	YesNo = 153,
	SingleSelection = 154,
	MultipleSelection = 155,
	Text = 156,
	OrderSelection = 157,
	Numeric = 171,
}

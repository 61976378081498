import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { distinctUntilChanged, ReplaySubject } from 'rxjs';

import { BenchmarkTestCase } from 'src/app/models/benchmark-test-case';

/**
 * Base component for benchmark report charts.
 */
@Component({
	selector: 'app-base-chart',
	standalone: true,
	imports: [],
	templateUrl: './base-chart.component.html',
	styleUrl: './base-chart.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseChartComponent {
	/**
	 * Setter for input test case, which pushes to a replaySubject.
	 */
	@Input({ required: true }) public set testCase(v) {
		this.testCaseSubject.next(v);
	}

	private testCaseSubject = new ReplaySubject<BenchmarkTestCase>(1);

	/**
	 * Observable of current test case.
	 * Since data should not be editable in this context, it is safe to not update unless test case UID changes.
	 */
	protected testCase$ = this.testCaseSubject.asObservable().pipe(distinctUntilChanged((prev, curr) => prev.testCaseUid === curr.testCaseUid));
}

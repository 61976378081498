<p-card header="Comments">
	<div class="card-content">
		<div class="positive-negative-container">
			<div class="comments-container">
				<p-card [style]="{ height: '100%', 'background-color': '#f0ffef' }">
					<ng-template pTemplate="header">
						<div class="card-header">
							<i class="pi pi-thumbs-up" style="font-size: 2rem"></i>
						</div>
					</ng-template>
					@for (comments of commentsForm.controls; track comments) {
						<div class="comments-form" [formGroup]="comments">
							<div>
								<b>{{ comments.value.vehicle }}</b>
							</div>
							@if (comments.controls.positive.value.length || comments.controls.positiveHighlighted.value.length) {
								<div class="forms-container">
									@for (positiveComment of (comments.controls.positiveHighlighted | formArray).controls; let index = $index; track positiveComment) {
										<div class="comment-control">
											<p-button icon="pi pi-star-fill" (onClick)="setCommentNotHighlighted(comments.controls.positiveHighlighted, index, CommentType.Positive)"></p-button>

											<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="positiveComment | formControl"></textarea>
											<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.positiveHighlighted, index)"></p-button>
										</div>
									}
									@for (positiveComment of (comments.controls.positive | formArray).controls; let index = $index; track positiveComment) {
										<div class="comment-control">
											<p-button icon="pi pi-star" [outlined]="true" (onClick)="setCommentHighlighted(comments.controls.positive, index, CommentType.Positive)"></p-button>

											<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="positiveComment | formControl"></textarea>
											<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.positive, index)"></p-button>
										</div>
									}
								</div>
							}
							<p-button icon="pi pi-plus" (onClick)="addComment(comments.controls.positive)"></p-button>
						</div>
					}
				</p-card>
			</div>
			<div class="comments-container">
				<p-card [style]="{ height: '100%', 'background-color': '#ffefef' }">
					<ng-template pTemplate="header">
						<div class="card-header">
							<i class="pi pi-thumbs-down" style="font-size: 2rem"></i>
						</div>
					</ng-template>
					@for (comments of commentsForm.controls; track comments) {
						<div class="comments-form" [formGroup]="comments">
							<div>
								<b>{{ comments.value.vehicle }}</b>
							</div>
							@if (comments.controls.negative.value.length || comments.controls.negativeHighlighted.value.length) {
								<div class="forms-container">
									@for (negativeComment of (comments.controls.negativeHighlighted | formArray).controls; let index = $index; track negativeComment) {
										<div class="comment-control">
											<p-button icon="pi pi-star-fill" (onClick)="setCommentNotHighlighted(comments.controls.negativeHighlighted, index, CommentType.Negative)"></p-button>

											<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="negativeComment | formControl"></textarea>
											<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.negativeHighlighted, index)"></p-button>
										</div>
									}
									@for (negativeComment of (comments.controls.negative | formArray).controls; let index = $index; track negativeComment) {
										<div class="comment-control">
											<p-button icon="pi pi-star" [outlined]="true" (onClick)="setCommentHighlighted(comments.controls.negative, index, CommentType.Negative)"></p-button>

											<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="negativeComment | formControl"></textarea>
											<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.negative, index)"></p-button>
										</div>
									}
								</div>
							}
							<p-button icon="pi pi-plus" (onClick)="addComment(comments.controls.negative)"></p-button>
						</div>
					}
				</p-card>
			</div>
		</div>
		<div class="comments-container">
			<p-card [style]="{ 'background-color': '#fffaef' }">
				<ng-template pTemplate="header">
					<div class="card-header">
						<i class="pi pi-comment" style="font-size: 2rem"></i>
					</div>
				</ng-template>
				@for (comments of commentsForm.controls; track comments) {
					<div class="comments-form" [formGroup]="comments">
						<div>
							<b>{{ comments.value.vehicle }}</b>
						</div>
						@if (comments.controls.neutral.value.length || comments.controls.neutralHighlighted.value.length) {
							<div class="forms-container">
								@for (neutralComment of (comments.controls.neutralHighlighted | formArray).controls; let index = $index; track neutralComment) {
									<div class="comment-control">
										<p-button icon="pi pi-star-fill" (onClick)="setCommentNotHighlighted(comments.controls.neutralHighlighted, index, CommentType.Neutral)"></p-button>

										<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="neutralComment | formControl"></textarea>
										<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.neutralHighlighted, index)"></p-button>
									</div>
								}
								@for (neutralComment of (comments.controls.neutral | formArray).controls; let index = $index; track neutralComment) {
									<div class="comment-control">
										<p-button icon="pi pi-star" [outlined]="true" (onClick)="setCommentHighlighted(comments.controls.neutral, index, CommentType.Neutral)"></p-button>

										<textarea pInputTextarea [style]="{ width: '100%' }" [autoResize]="true" [formControl]="neutralComment | formControl"></textarea>
										<p-button icon="pi pi-trash" severity="danger" (onClick)="removeComment(comments.controls.neutral, index)"></p-button>
									</div>
								}
							</div>
						}
						<p-button icon="pi pi-plus" (onClick)="addComment(comments.controls.neutral)"></p-button>
					</div>
				}
			</p-card>
		</div>
	</div>
</p-card>

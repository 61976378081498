import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { combineLatest, finalize, map, shareReplay, take, tap } from 'rxjs';

import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { UseUtcDirective } from 'src/app/directives/use-utc-time.directive';
import { TestRun } from 'src/app/models/test-run';
import { BenchmarkSearchService } from 'src/app/pages/benchmark/components/benchmark-search/services/benchmark-search.service';
import { BenchmarkFormService } from 'src/app/pages/benchmark/services/benchmark-form.service';
import { BenchmarkReportService } from 'src/app/pages/benchmark/services/benchmark-report.service';
import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';
import { DateRangeValidator } from 'src/app/validators/date-range.validator';

const MILLISECONDS_PER_WEEK = 604800000;

/**
 * Performs search for test runs by date, and filtered by user in table.
 * Allows for generating report from selected test runs.
 */
@Component({
	selector: 'app-benchmark-search',
	standalone: true,
	imports: [PanelModule, MultiSelectModule, DropdownModule, CalendarModule, SkeletonModule, ButtonModule, TableModule, UseUtcDirective, ReactiveFormsModule, AsyncPipe, DatePipe, FormsModule],
	templateUrl: './benchmark-search.component.html',
	styleUrl: './benchmark-search.component.scss',
	providers: [ReportingGraphQLService, BenchmarkSearchService, BenchmarkReportService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenchmarkSearchComponent implements OnInit {
	public searchForm = new FormGroup({
		DateRange: new FormControl<(Date | null)[]>([new Date(Date.now() - MILLISECONDS_PER_WEEK * 4), new Date()], [DateRangeValidator()]),
	});

	public selectedTestSuite = new FormControl<string>('');

	public selectedTestRuns: TestRun[];

	// Placeholder data for skeleton rows
	public skeletonData = Array.from(Array(5), (_, index) => index + 1);

	// Placeholder data for skeleton columns
	public skeletonColumns = Array.from(Array(4), (_, index) => index + 1);

	public testSuiteOptions$ = this.benchmarkSearchService.testRuns$.pipe(
		map(testRuns => {
			return Array.from(new Set(testRuns.filter(testRun => testRun.TSName).map(testRun => testRun.TSName))).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		}),
	);

	public availableTestRuns$ = combineLatest({
		testRuns: this.benchmarkSearchService.testRuns$,
		testSuite: this.selectedTestSuite.valueChanges.pipe(tap(() => (this.selectedTestRuns = []))),
	}).pipe(
		map(source => {
			if (!source.testSuite) {
				return [];
			} else {
				return source.testRuns.filter(testSuite => testSuite.TSName === source.testSuite);
			}
		}),
		shareReplay(1),
	);

	public vehicleOptions$ = this.availableTestRuns$.pipe(
		map(testRuns => {
			return Array.from(new Set(testRuns.map(testRun => testRun.VehicleName))).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		}),
	);

	public userOptions$ = this.availableTestRuns$.pipe(
		map(testRuns => {
			return Array.from(new Set(testRuns.map(testRun => testRun.ExecutedBy))).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		}),
	);

	/**
	 * Constructor.
	 * @param benchmarkSearchService To perform search.
	 * @param benchmarkReportService To create report.
	 * @param benchmarkFormService To setup benchmark report form.
	 * @param messageService To send error messages.
	 * @param router
	 * @param route
	 * @param activatedRoute
	 */
	constructor(
		public benchmarkSearchService: BenchmarkSearchService,
		private benchmarkReportService: BenchmarkReportService,
		private benchmarkFormService: BenchmarkFormService,
		private messageService: MessageService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	/**
	 * Perform first-time search using default search conditions.
	 */
	ngOnInit(): void {
		const testRuns: string = this.activatedRoute.snapshot.queryParams['testRuns'];
		if (testRuns) {
			// Turn test runs back into an array, then format into expected object structure.
			this.selectedTestRuns = testRuns.split(',').map(testRunUid => {
				return { TestRunUid: testRunUid };
			});
			this.generateReport();
		}
		this.performTestRunSearch();
	}

	/**
	 * Performs a search based on current form values.
	 */
	public performTestRunSearch(): void {
		const dateRangeValue = this.searchForm.controls.DateRange.value;
		if (dateRangeValue[0] && dateRangeValue[1]) {
			this.benchmarkSearchService.performSearch({ fromDate: dateRangeValue[0], toDate: dateRangeValue[1] });
		}
	}

	/**
	 * Generates a report and updates report form with new value.
	 * @returns Nothing.
	 */
	public generateReport(): void {
		if (!this.selectedTestRuns?.length) {
			this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No test runs selected.' });
			return;
		}

		const testRunUids = this.selectedTestRuns.map(testRun => testRun.TestRunUid);

		// When generating report, stringify test runs and put into query parameters.
		// This allows for restoration of state on reload, and enables link sharing.
		const queryParams: Params = { testRuns: testRunUids.join(',') };

		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams,
			queryParamsHandling: 'merge',
			replaceUrl: true,
		});

		this.benchmarkFormService.reportLoading.next(true);
		this.benchmarkReportService
			.generateBenchmarkReport$(testRunUids)
			.pipe(
				tap(report => {
					if (report) {
						this.benchmarkFormService.setupFormValuesFromBenchmarkReport(report);
					}
				}),
				take(1),
				finalize(() => this.benchmarkFormService.reportLoading.next(false)),
			)
			.subscribe();
	}
}
